import { Controller } from "@hotwired/stimulus"
import { themeId } from "../editor/constants"
import * as monaco from 'monaco-editor'

export default class extends Controller {
  static values = {
    language: {
      type: String,
      default: 'Dentaku',
    },
    theme: {
      type: String,
      default: themeId,
    },
    content: String,
    complete: Boolean,
  }

  connect() {
    if (!this.completeValue) this.perform()
  }

  async perform() {
    const colorizedContent = await monaco.editor.colorize(this.contentValue, this.languageValue, { theme: this.themeValue })
    this.element.innerHTML = colorizedContent
    this.completeValue = true
  }
}
